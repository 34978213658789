// color variables
$primary: rgba(101, 201, 255, 1);
$primary-hover: rgba(81, 161, 204, 1);
$secondary: #f6fcff;
$secondary-hover: rgba(240, 250, 255, 1);
$light: #ffffff;
$body: #484848;
$clear: rgba(255, 255, 255, 0);
$hair: #724133;
$black10: rgba(0, 0, 0, 0.1);
