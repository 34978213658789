@import '../../variables.scss';

.navbar {
  background-color: $primary;
  height: 80px;
  display: flex;
  justify-content: center;
  z-index: 999;
  position: fixed;
  bottom: 0;
  top: auto;
  width: 100%;
  transition: background-color 1s ease-in-out;
}

.navlinkscontainer {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
  max-width: 30rem;
  justify-content: space-evenly;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.navlink {
  text-decoration: none;
  color: $light;
  font-size: 1rem; // 20px
  position: relative;
  padding-bottom: 6px;
  box-shadow: 0px 2px 0px $clear;
  transition: color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: 0px 2px 0px $light;
    transition: color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  }
}

@media (min-width: 480px) {
  .navbar {
    height: 60px;
    top: 0;
    bottom: auto;
    background-color: $light;

    &.navbar_scroll {
      background-color: $primary;

      .navlink {
        color: $light;

        &:hover {
          box-shadow: 0px 2px 0px $light;
        }
      }
    }

    .navlink {
      color: $primary;

      &:hover {
        box-shadow: 0px 2px 0px $primary;
      }
    }
  }
}
