@import '../../variables.scss';

.resumesection {
  padding: 6rem 1rem;
  max-width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.resumeheadline {
  position: relative;

  &::after {
    content: '';
    height: 2px;
    width: 2rem;
    background-color: $primary;
    position: absolute;
    bottom: -1rem;
    left: 0.5rem;
  }
}

.skillscontainer {
  margin-bottom: 6rem;
}

.skillsblock {
  margin-top: 3rem;

  &:first-of-type {
    margin-top: 3rem;
  }

  p {
    line-height: 140%;
    color: $body;
  }
}

.resumeblock {
  margin-top: 6rem;

  &:first-of-type {
    margin-top: 3rem;
  }

  ul {
    margin: 0;
    padding: 0;
    margin-top: 1.25rem;
    margin-left: 1.25rem;
  }

  li {
    margin-top: 0.5rem;
    line-height: 140%;
    color: $body;

    &:first-of-type {
      margin-top: 0;
    }
  }

  p {
    line-height: 140%;
    color: $body;
  }
}

.resumeblocktitle {
  color: $primary;
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0 0 0.5rem;
}

.resumedates {
  font-size: 1rem;
  color: $primary;
  font-weight: 400;
  letter-spacing: 0.05em;
}

@media (min-width: 480px) {
  .resumesection {
    flex-direction: row;
    max-width: 50rem;
  }

  .skillscontainer {
    width: 30%;
    margin-bottom: 0;
  }

  .workexperience {
    width: 70%;
    border-left: 2px solid $primary;
    padding-left: 3rem;
    margin-left: 3rem;
  }
}
