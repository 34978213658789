@import '../../variables.scss';

.footercontainer {
  background-color: $primary;
  height: 80px;
  position: relative;
}

// back to top
.backtotop {
  position: absolute;
  top: -4rem;
  left: 0;
  right: 0;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
}

.backtotoplink {
  color: $primary;
  animation: floating 1s ease infinite alternate;

  .arrowupicon {
    padding-left: 0.5rem;
    margin-bottom: -0.15rem;
  }
}

@media (min-width: 480px) {
  .footercontainer {
    height: 30px;
  }
}

// animations
@keyframes floating {
  from {
    transform: translateY(0.5rem);
  }
  to {
    transform: translateY(-0.5rem);
  }
}
