@import '../../variables.scss';

.button {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  font-size: 1rem;
  border-radius: 4px;
  text-decoration: none;
  transition: 0.2s ease-in-out;
  font-family: inter, sans-serif;

  &:hover {
    cursor: pointer;
  }

  // prop variety
  // colors - primary, secondary
  // size - small. large
  &.primary {
    background-color: $primary;
    border: 2px solid $black10;
    color: $light;

    &:hover,
    &:focus,
    &:active,
    &:focus-visible {
      background-color: $primary-hover;
    }
  }

  &.secondary {
    background-color: $light;
    border: 2px solid $primary;
    color: $primary;

    &:hover {
      background-color: $secondary-hover;
    }
  }

  &.small {
    padding: 0.5rem 1.5rem;
    font-weight: 500;
  }

  &.large {
    padding: 1rem 2rem;
    font-weight: 600;
  }
}
