@import '../../variables.scss';

.aboutsection {
  padding: 6rem 1rem;
  max-width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

// avatar section
.avatarcontainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  button {
    margin-top: -3.5rem;
  }
}

.avatar {
  width: 100%;
  max-width: 375px;
  max-height: 375px;
  object-fit: contain;
}

.avatarsvg {
  width: 100%;
  min-width: 300px;
}

.contactemail,
.contactnumber {
  font: 1rem;
  margin-top: 0.5rem;
  color: $primary;
  font-weight: 400;
}

// copy section
.aboutinfocontainer {
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 45rem;
}

.aboutheadline {
  font-size: 2.5rem;
  font-weight: 700;
  margin: 0 0 0.15rem;
  color: $primary;
}

.abouttitle {
  margin: 0 0 1rem;
  font-size: 1rem;
  font-weight: 500;
  color: $primary;
}

.aboutparagraph {
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  color: $body;
  line-height: 140%;
}

// social media links
.sociallinkscontainer {
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  margin-top: 1rem;
}

.socialicon {
  max-height: 50px;
  max-width: 50px;
  width: 100%;
  height: 100%;
  object-fit: contain;
  color: $primary;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: $primary-hover;
  }
}

@media (min-width: 768px) {
  .aboutsection {
    flex-direction: row;
  }

  .avatarcontainer {
    width: 40%;
  }

  .aboutinfocontainer {
    width: 60%;
  }

  .abouttitle {
    font-size: 1.15rem;
  }
}

// avatar animation
#righteyelid,
#lefteyelid {
  animation: blink 3.5s ease-in-out infinite 2s alternate;
  transform-origin: center;
  transform-box: fill-box;
}

@keyframes blink {
  0% {
    transform: translateY(0);
  }

  3% {
    transform: translateY(19.5px);
  }

  6% {
    transform: translateY(0);
  }
}

#righteyebrow,
#lefteyebrow {
  animation: raisebrow 8s ease-in-out infinite 2s forwards;
  transform-origin: center;
  transform-box: fill-box;
}

@keyframes raisebrow {
  0% {
    transform: translateY(0);
  }

  10% {
    transform: translateY(-5px);
  }

  50% {
    transform: translateY(-5px);
  }

  60% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0);
  }
}

#righteye,
#lefteye {
  animation: grow 8s ease-in-out infinite 2s forwards;
  transform-origin: center;
  transform-box: fill-box;
}

@keyframes grow {
  0% {
    transform: scale(1);
  }

  10% {
    transform: scale(1.1);
  }

  50% {
    transform: scale(1.1);
  }

  60% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

#toplip,
#bottomlip {
  transition: transform 0.25s ease-in-out;
}

.avatar {
  &:hover {
    #toplip {
      transform: translateY(-15px) !important;
    }

    #bottomlip {
      transform: translateY(15px) !important;
    }
  }
}
