@import '../../variables.scss';

.avatar {
  width: 100%;
  max-width: 375px;
  max-height: 375px;
  object-fit: contain;
}

.clickme {
  display: block;
}

.hoverme {
  display: none;
}

@media (hover: hover) {
  .clickme {
    display: none;
  }

  .hoverme {
    display: block;
  }
}

// avatar animation
#righteyelid,
#lefteyelid {
  animation: blink 3.5s ease-in-out infinite 2s alternate;
  transform-origin: center;
  transform-box: fill-box;
}

@keyframes blink {
  0% {
    transform: translateY(0);
  }

  3% {
    transform: translateY(19.5px);
  }

  6% {
    transform: translateY(0);
  }
}

#righteyebrow,
#lefteyebrow {
  animation: raisebrow 8s ease-in-out infinite 2s forwards;
  transform-origin: center;
  transform-box: fill-box;
}

@keyframes raisebrow {
  0% {
    transform: translateY(0);
  }

  10% {
    transform: translateY(-5px);
  }

  50% {
    transform: translateY(-5px);
  }

  60% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0);
  }
}

#righteye,
#lefteye {
  animation: grow 8s ease-in-out infinite 2s forwards;
  transform-origin: center;
  transform-box: fill-box;
}

@keyframes grow {
  0% {
    transform: scale(1);
  }

  10% {
    transform: scale(1.1);
  }

  50% {
    transform: scale(1.1);
  }

  60% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

#toplip,
#bottomlip {
  transition: transform 0.25s ease-in-out;
}

.avatar {
  &:hover {
    #toplip {
      transform: translateY(-15px) !important;
    }

    #bottomlip {
      transform: translateY(15px) !important;
    }
  }
}
